/* global Component, axios */
class PersonGenericComponentB2C extends Component {

    static name() {
        return "PersonGenericComponentB2C";
    }

    static componentName() {
        return "PersonGenericComponentB2C";
    }

    getProps() {
        return {
            "useLastName2":{
                type:Boolean,
                default:false
            },
            'personObj':{
                type:Object,
                required:false
            },
            'confirmPassword':{
                type:Boolean,
                default:false
            }
        };
    }

    data() {
        return {
            _response: null,
            alertError: [],
            alertErrorAddress: [],
            alertParams: [],
            getTaxNrLabel: 'CUIT',
            personEnabled: false,
            AddCompanyAddressFlag: false,
            person: {
                Code: null,
                WebUserFlag: true,
                ContactType: null,
                Gender:null,
                Name: "",
                LastName: "",
                LastName2: "",
                Phone: "",
                Email: "",
                JobPosition: null,
                WebUser: "",
                WebPassword: "",
                WebPassword2:null,
                WebAccessGroup: "",
                CountryCode: null,
                Country: "",
                TaxNr: "",
                Customer: "",
            },
            customer: {
                Code: null,
                Name: "",
                //FantasyName: "",
                TaxRegNr: "",
                TaxRegType: "1"
            },
            customerAddress:{
                Name: '',
                normalizeAddress:null,
                Address: '',
                Country: '',
                CountryName:null,
                ProvinceCode: '',
                Province: null,
                Locality:'',
                ZipCode:'',
                City: '',
                Street:'',
                StreetNumber:'',
                Floor:'',
                Apartment:"",
                Latitude:null,
                Longitude:null,
                normalize:false,
                CustomLocation:false
            },
            readyNormalize:false
        };
    }

    getWatch() {
        return {
            "addressRequired": function (newValue,oldVal) {
                if(newValue){
                    this.AddCompanyAddressFlag = newValue;
                }
            },
            "isResponInsp":function (newVal,oldVal) {
                if(newVal)
                    this.AddCompanyAddressFlag = true;
            },
            'customerAddress.Country': function () {
                if (this.customerAddress.Country){
                    this.person.Country;
                    this.customer.Country;
                }
            },
            "person.Email":function (newValue, oldValue){
                if(oldValue === this.person.WebUser)
                    this.person.WebUser = newValue;
            },
            "person.Phone":function (newValue, oldValue){
                if(this.$store.getters.getSetting.PhoneValidator)
                    if(newValue && oldValue) {
                        let newValueReplaced = newValue.replace(/ /g, "").replace(/\D/g, "");
                        if (this.phonePattern.test('+' + newValueReplaced))
                            this.person.Phone = "+" + newValueReplaced;
                        else
                            this.person.Phone = newValueReplaced;
                    }
            },
            'customerAddress.ProvinceCode': function (val,oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.City': function (val,oldval) {
                if (this.customer.City)
                    this.person.City;
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.Locality': function (val,oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.Street': function (val,oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.StreetNumber': function (val,oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.Floor': function () {
                this.buildAddress();
            },
            'customerAddress.Apartment': function () {
                this.buildAddress();
            }
        };
    }

    created() {
        return function () {
            if (this.personObj)
                this.person = this.personObj;
        };
    }

    mounted() {
        return function () {

            this.subscribeEvent("address-relocation",this.triggerSaveAction);
            this.subscribeEvent("address-relocation-cancel",this.triggerSaveAction);

            this.clearForm.bind(this);
            this.registerNewPerson.bind(this);
            this.AddCompanyAddressFlag = this.addressRequired;
            this.$store.dispatch('updateSelectLinkto', {
                table: "modalPerson_Country",
                value: this.$store.getters.getOurSettingsCountry
            });

            this.person.Country = this.$store.getters.getOurSettingsCountry;
            this.customerAddress.Country = this.$store.getters.getOurSettingsCountry;
            this.subscribeEvent('person-reset', this.clearForm);
            this.subscribeEvent('person-save', this.triggerSaveAction);
            this.triggerTaxNrType();
            $('#customerTaxRegType').tooltip();
            $('#WebPassword').tooltip();
        };
    }

    getComputed() {
        return {
            addressRequired:function (){
                return this.$store.getters.AddressRequiredInRegister;
            },
            showCountry:function (){
                return this.$store.getters.showCountryAddress;
            },
            customClass:function (){
                return {};
            },
            stringPattern:function (){
                return this.$store.getters.getPhoneMaskPattern;
            },
            phonePattern: function (){
                if(this.$store.getters.getSetting.PhoneValidator)
                    return new RegExp(this.stringPattern);
                return null;
            },
            provinceFilter() {
                //console.log('Reload Province Filter');
                let filter = {};
                if (this.customerAddress.Country)
                    filter['Country'] = this.customerAddress.Country;
                return filter;
            },
            cityFilter() {
                let filter = {};
                if (this.customerAddress.ProvinceCode)
                    filter['Province'] = this.customerAddress.ProvinceCode;
                return filter;
            },
            localityFilter() {
                let filter = {};
                if (this.customerAddress.ProvinceCode)
                    filter['ProvinceCode'] = this.customerAddress.ProvinceCode;
                return null;
            },
            zipCodeFilter() {
                let filter = {};
                if (this.customerAddress.LocalityCode)
                    filter['LocalityCode'] = this.customerAddress.LocalityCode;
                return null;
            },
            isConsFinal: function () {
                if (this.customer.TaxRegType != null) {
                    return this.customer.TaxRegType === "1";
                }
                return false;
            },
            isResponInsp: function () {
                if (this.customer.TaxRegType != null) {
                    return this.customer.TaxRegType != "1";
                }
                return false;
            },
            inInsertMode: function () {
                if (this.personObj)
                    return false;
                return true;
            },
            getCustomerTaxRegTypes(){
                let settingsType = this.$store.getters.getSetting.CustomerIDTypes;
                if(settingsType){
                    let codes = settingsType.split(",");
                    let filtertypes = [];
                    for(let regType of this.$store.getters.getCustomerTaxReg)
                        if(codes.indexOf(regType.value.toString()) !== -1)
                            filtertypes.push(regType);
                    return filtertypes;
                }
                return this.$store.getters.getCustomerTaxReg;
            }
        };
    }

    triggerTaxNrType() {
        let label = "CUIT";
        if (this.customer.TaxRegType === "1") {
            label = "DNI";
        }
        this.getTaxNrLabel = label;
    }

    async triggerSaveAction(eventAddress){
        if(!this.$store.state.screenState)
            this.$store.dispatch('toogleLockScreen',this.tr('Processing'));

        this.checkPersonFields();
        if (this.AddCompanyAddressFlag){
            this.checkAddressFields();
        }

        if (this.alertError || this.alertErrorAddress){
            window.scrollTo(0,0);
            return;
        }

        let address = this.customerAddress;
        if(eventAddress)
            address = eventAddress;
        let openMap = address.normalize;
        if(openMap) {
            this.$store.dispatch('toogleLockScreen');
            this.emitEvent("addressNeedConfirmations",address);
        }
        else {
            this.alertError = [];
            this.alertErrorAddress = [];
            if (this.alertError.length === 0  && this.alertErrorAddress === 0) {
                let data = {
                    personData: this.person,
                    customerData: this.customer,
                    createCustomer: true,
                    customerAddress: address,
                    addAddressFlag: this.AddCompanyAddressFlag,
                };
                window.scrollTo(0, 0);
                try {
                    let response = await axios.post('/ecommerce/api/newPerson', data);
                    if (response) {
                        this._response = response;
                        if (this._response.data.success) {
                            this.person.Code = this._response.data.personCode;
                            this.alertError = [];
                            this.clearForm();
                            this.closeModal();
                            if (!this.isLogged)
                                this.emitEvent('registerSuccess');
                        } else {
                            if (Array.isArray(this._response.data.errors)) {
                                if (typeof (this._response.data.errors[0]) === 'string')
                                    this.alertError = this._response.data.errors[0];
                                else if (this._response.data.errors[0].code)
                                    this.alertError = [this._response.data.errors[0].code, this._response.data.errors[0].params.FieldName];
                            } else
                                this.alertError = this._response.data.errors;
                            window.scrollTo(0, 0);
                        }
                    }
                }
                catch (error) {
                    this.alertError = "Server Response" + error;
                }
            }
        }
        if(this.$store.state.screenState)
            this.$store.dispatch('toogleLockScreen');
    }

    confirmAddressTrigger(address){
        if(address.Address === this.customerAddress.Address)
            this.customerAddress = address;
    }

    buildAddress() {
        let newAddress = '';
        if (this.customerAddress.Street) {
            newAddress = this.customerAddress.Street + " ";
        }
        if (this.customerAddress.StreetNumber) {
            newAddress += this.customerAddress.StreetNumber + " ";
        }
        if (this.customerAddress.Floor) {
            newAddress += this.customerAddress.Floor + " ";
        }
        if (this.customerAddress.Apartment)
            newAddress += this.customerAddress.Apartment + " ";

        this.customerAddress.Address = newAddress;
    }

    clearForm(force = false) {
        this.alertError = [];
        this.alertErrorAddress = [];
        if (this.inInsertMode && force)
            return;
        for (let dataEntry of Object.keys(this.person))
            this.person[dataEntry] = null;
        for (let dataEntry of Object.keys(this.customer))
            this.customer[dataEntry] = null;

        this.$store.dispatch('clearSelectsLinkto');
        this.updateSelectValues();
    }

    addressPasteField(data,sourceName,target){
        if(data.linkToData && data.linkToData.length > 0)
            this.customerAddress[target] = data.linkToData[0][sourceName];
    }

    updateSelectValues() {
        this.customerAddress.Country = this.$store.getters.getSelectLinkToValue('modalPerson_Country');
    }

    checkAddressFields(){
        if(this.AddCompanyAddressFlag){
            if (!this.customerAddress.Street)
                this.alertErrorAddress = ['Please fill the field {{$1}}', "Street"];
            if (!this.customerAddress.StreetNumber)
                this.alertErrorAddress = ['Please fill the field {{$1}}', "Number"];
            if (!this.customerAddress.City)
                this.alertErrorAddress = ['Please fill the field {{$1}}', "City"];
            if (!this.customerAddress.ProvinceCode)
                this.alertErrorAddress = ['Please fill the field {{$1}}', "Province"];
        }
    }

    checkPersonFields(){
        switch ("") {
            case this.person.Name:
                this.alertError = ['Please fill the field {{$1}}', "Name"];
                break;
            case this.person.LastName:
                this.alertError = ['Please fill the field {{$1}}', "Last Name"];
                break;
            case this.person.Phone:
                this.alertError = ['Please fill the field {{$1}}', "Phone"];
                break;
            case this.person.Email:
                this.alertError = ['Please fill the field {{$1}}', "Email"];
                break;
            case this.person.WebUser:
                this.alertError = ['Please fill the field {{$1}}', "User"];
                break;
            case this.person.WebPassword:
                this.alertError = ['Please fill the field {{$1}}', "Password"];
                break;
        }

        if(this.useLastName2 && this.person.LastName2 === "")
            this.alertError = ['Please fill the field {{$1}}', "Mothers Maiden Name"];

        if(this.person.Gender === null)
            this.alertError = ['Please fill the field {{$1}}', "Gender"];

        const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!mailReg.test(this.person.Email))
            this.alertError = ['FORMATVALUEERR', "Email"];

        let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        if(!strongRegex.test(this.person.WebPassword) && !mediumRegex.test(this.person.WebPassword))
            this.alertError = ["Password must include uppercase, lowercase and number"];
        if(this.person.WebPassword.length < 6)
            this.alertError = ["Password length must be greater than {{$1}}","5"];

        if(this.confirmPassword)
            if(this.person.WebPassword != this.person.WebPassword2)
                this.alertError = ["Password doesn't match"];

        if(!this.phonePattern.test(this.person.Phone))
            this.alertError = ["FORMATVALUEERR", "Phone"];

        if(!this.customer.TaxRegNr)
            this.alertError = ['Please fill the field {{$1}}',  this.getTaxNrLabel];

    }
    async registerNewPerson() {
        this.checkPersonFields();
        if (!this.alertError && this.AddCompanyAddressFlag){
            this.checkAddressFields();
        }
        if (this.alertError.length === 0  && this.alertErrorAddress === 0) {
            this.triggerSaveAction();
        }
        else
            window.scrollTo(0,0);
    }

    closeModal() {
        $("#PersonModal").modal('toggle');
    }

    loadTaxNr() {
        axios.post('/ecommerce/api/getRecordByFilter', {
            record: 'Customer',
            filter: {
                TaxRegNr: this.TaxRegNr
            }
        })
            .then((response) => {
                // eslint-disable-next-line no-console
                console.log(response);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    processEvent() {
        // eslint-disable-next-line no-console
        console.log("Receive Event From Container");
    }

    getMethods() {
        return {
            triggerTaxNrType:this.triggerTaxNrType,
            triggerSaveAction:this.triggerSaveAction,
            confirmAddressTrigger: this.confirmAddressTrigger,
            buildAddress:this.buildAddress,
            clearForm: this.clearForm,
            addressPasteField:this.addressPasteField,
            updateSelectValues:this.updateSelectValues,
            checkAddressFields:this.checkAddressFields,
            checkPersonFields:this.checkPersonFields,
            registerNewPerson: this.registerNewPerson,
            closeModal: this.closeModal,
            loadTaxNr:this.loadTaxNr,
            processEvent:this.processEvent
        };
    }

    getTemplate() {
        return `<form autocomplete="off" class="ecommerce-b2">
                 <div class="col-sm-12" v-if="alertError.length>0">
                      <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                           <strong>{{tr(alertError)}}!</strong>
                           <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError=[]">
                            <span aria-hidden="true">&times;</span>
                           </button>
                       </div>
                   </div>
                   <div class="row" :class="customClass">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="firstname" class="form-label">{{tr('Name')}}</label>
                                <input id="personName" v-model="person.Name" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="lastname" class="form-label">{{tr('Last Name')}}</label>
                                <input id="personLastName" v-model="person.LastName" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" >
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="useLastName2">
                            <div class="form-group">
                                <label for="lastname" class="form-label">{{tr('Mothers Maiden Name')}}</label>
                                <input id="personLastName2" v-model="person.LastName2" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" >
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="personPhone" class="form-label">{{tr('Phone')}}</label>
                                <input id="personPhone" v-model="person.Phone" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" :placeholder="tr('ECOMPHONEMASK')" :pattern="phonePattern.source" >
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="personGender" class="form-label">{{tr('Gender')}}</label>
                                <select id="personGender" v-model="person.Gender" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" >
                                    <option value="0" >{{tr('Male')}}</option>
                                    <option value="1" >{{tr('Female')}}</option>
                                    <option value="2" >{{tr('Transgender Female')}}</option>
                                    <option value="3" >{{tr('Transgender Male')}}</option>
                                    <option value="4" >{{tr('Gender Variant or Non-Conforming')}}</option>
                                    <option value="5" >{{tr('Not Listed')}}</option>
                                    <option value="6"> {{tr('Prefer Not to Answer')}}</option> 
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                               <label for="personMail" class="form-label">{{tr('Mail')}} (Se utilizará como Usuario)</label>
                               <input id="personMail" v-model="person.Email" type="email" class="form-control" aria-autocomplete="none" autocomplete="none">
                         </div>
                        </div>
                        <div class="col-sm-6" v-if="!inInsertMode">
                            <div class="form-group">
                               <label for="WebAccessGroup" class="form-label">{{tr('Web Access Group')}}</label>
                               <selectLinkToComponent recordKey="modalPerson" :table="'WebAccessGroup'" :paste="'Code'" showField="Code" :selected="person.WebAccessGroup" :filters="{ CustCode:$store.state.session.fields.CustCode }"  v-on:update-value="updateSelectValues"> </selectLinkToComponent>
                            </div>
                        </div>
                        <div class="col-sm-6 d-none">
                            <div class="form-group">
                               <label for="WebUser" class="form-label">{{tr('User')}}</label>
                               <input id="WebUser" v-model="person.WebUser" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                           </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                               <label for="WebPassword" class="form-label">{{tr('Password')}}</label>
                               <input id="WebPassword" v-model="person.WebPassword" type="password" class="form-control" aria-autocomplete="none" autocomplete="new-password" data-trigger="focus" data-container="body" data-toggle="tooltip" data-placement="top" :title="tr('It must contain at least 6 digits, capital letter, lowercase letter and number')">
                             </div>
                        </div>
                        <div class="col-sm-6" v-if="confirmPassword">
                            <div class="form-group">
                               <label for="WebPassword" class="form-label">{{tr('Confirm Password')}}</label>
                               <input id="WebPassword" v-model="person.WebPassword2"  type="password" class="form-control" aria-autocomplete="none" autocomplete="new-password">
                           </div>
                        </div>
                        <div class="col-sm-6" v-if="!inInsertMode">
                            <div class="form-group">
                               <label class="form-label">{{tr('User Activation')}}</label>
                               <div class="custom-control custom-checkbox mb-3">
                                  <input id="EnableCheck" class="custom-control-input" type="checkbox" v-model="person.WebEnabled" aria-autocomplete="none" autocomplete="none">
                                  <label for="EnableCheck" class="custom-control-label">{{tr('Enabled')}}</label>
                               </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                               <label for="customerTaxRegType" class="form-label">{{tr('Inscription')}}</label>
                               <select id="customerTaxRegType" v-model="customer.TaxRegType" type="text" class="form-control"  v-on:change.after="triggerTaxNrType"  :disabled="getCustomerTaxRegTypes.length==1">
                                    <option value="null" >{{tr("Select One")}}</option>
                                    <template v-for="regType of getCustomerTaxRegTypes">
                                        <option :value="regType.value" >{{tr(regType.label)}}</option>
                                    </template>
                                </select>
                             </div>
                        </div>
                        <div class="col-sm-6">
                            <template v-if="isResponInsp">
                                <div class="form-group">
                                    <label for="customerTaxRegNr" class="form-label">{{getTaxNrLabel}}</label>
                                    <input id="customerTaxRegNr" v-model="customer.TaxRegNr" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                            </template>
                            <template v-else>
                                <div class="form-group">
                                    <label for="customerTaxRegNr" class="form-label">{{getTaxNrLabel}}</label>
                                    <input id="customerTaxRegNr" v-model="customer.TaxRegNr" type="number" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                            </template>
                            
                         </div>
                         <div class="col-sm-6"  v-if="customer.TaxRegType==null">
                            <div class="form-group">
                               <div class="alert alert-info" role="alert">
                                    {{tr('Please select a Tax Type to complete necessary data')}}.
                                </div>
                             </div>
                         </div>
                        <template v-if="isResponInsp">
                             <div class="col-sm-6">
                                <div class="form-group">
                                   <label for="customerName" class="form-label">{{tr('Business Name')}}</label>
                                   <input id="customerName" v-model="customer.Name" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                 </div>
                             </div>
                       </template>
                </div>
                <div class="block-header">
                    <h5 class="text-center">{{tr('ECOMADDRESS')}}</h5>
                </div>
                <hr>
                <template v-if="!isResponInsp && !addressRequired">
                    <div class="custom-control custom-checkbox ml-2" :class="{disabled:isResponInsp}">
                          <input type="checkbox" class="custom-control-input" id="AddCompanyAddressFlag" v-model="AddCompanyAddressFlag" :disabled="isResponInsp">
                          <label class="custom-control-label" for="AddCompanyAddressFlag">{{tr('Do you want to add address now? You can do it later')}}.</label>
                    </div>
                </template>
                
                <template v-if="AddCompanyAddressFlag">
                            <div class="col-sm-12" v-if="alertErrorAddress.length>0">
                             <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                                   <strong>{{tr(alertErrorAddress)}}!</strong>
                                   <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertErrorAddress=[]">
                                    <span aria-hidden="true">&times;</span>
                                   </button>
                               </div>
                              </div>
                            <div class="row mt-4">
                                <div class="col-sm-12" >
                                    <div class="form-group" >
                                       <label for="selectedAddress" class="form-label">{{tr('Street')}}</label>
                                       <input id="selectedAddressStreet" v-model="customerAddress.Street" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3" >
                                  <div class="form-group" >
                                       <label for="selectedAddress" class="form-label">{{tr('Number')}}</label>
                                       <input id="selectedAddressNumber" v-model="customerAddress.StreetNumber" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                  </div>
                                </div>
                                <div class="col-12 col-md-3" >
                                  <div class="form-group" >
                                       <label for="selectedAddress" class="form-label">{{tr('Floor')}} ({{tr('Optional')}})</label>
                                       <input id="selectedAddressFlat" v-model="customerAddress.Floor" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                  </div>
                                </div>
                                <div class="col-12 col-md-6" >
                                  <div class="form-group" >
                                       <label for="selectedAddress" class="form-label">{{tr('Apartment')}} ({{tr('Optional')}})</label>
                                       <input id="selectedAddressApartment" v-model="customerAddress.Apartment" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                  </div>
                                </div>
                                <div class="col-sm-12 d-none" >
                                    <div class="form-group" >
                                       <label for="customerAddress" class="form-label">{{tr('Address')}}</label>
                                       <textarea id="customerAddress" v-model="customerAddress.Address" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                       </textarea>
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="showCountry">
                                    <div class="form-group">
                                        <label for="customerCountry" class="form-label">{{tr('Country')}}</label>
                                        <selectLinkToComponent  v-model="customerAddress.Country" recordKey="modalPerson"  :table="'Country'" :paste="'Code'" :showField="'Name'" :selected="customerAddress.Country"  @update-value-full="(data)=> addressPasteField(data,'Name','CountryName' )"> </selectLinkToComponent>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label class="form-label">{{tr("Province")}}</label>
                                        <selectLinkToComponent v-model="customerAddress.ProvinceCode" recordKey="modalPerson" :table="'Province'" :paste="'Code'" :showField="'Name'" :filters="provinceFilter" :selected="customerAddress.ProvinceCode"  @update-value-full="(data)=> addressPasteField(data,'Name','Province' )"> </selectLinkToComponent>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label class="form-label">{{tr('City')}}</label>
                                        <selectLinkToComponent v-model="customerAddress.City" recordKey="modalPerson"  :table="'City'" :paste="'Code'" :showField="'Code'"  :filters="cityFilter" :selected="customerAddress.City" > </selectLinkToComponent>
                                     </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="form-label">{{tr('Locality')}}</label>
                                    <input id="customLocality" v-model="customerAddress.Locality" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                                <div class="col-sm-6">
                                    <label class="form-label">{{tr('ZipCode')}}</label>
                                    <input id="customZipCode" v-model="customerAddress.ZipCode" type="number" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                           </div>
                    </template>
                <hr>
              </form>`;
    }
}

PersonGenericComponentB2C.registerComponent();
